import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FontSize from "../../../utils/FontSize";
import MainNews from "../MainNews";
import news from "../../../News";
import NewsCard from "./NewsCard";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 76,
    paddingBottom: 94,
    [theme.breakpoints.down("md")]: {
      maxWidth: "450px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  container: {
    paddingBottom: 62,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  header: {
    textAlign: "center",
  },
  ghostDefiImg: {
    borderRadius: 15,
    width: "100%",
    maxWidth: "100%",
    height: '100%',
    maxHeight: '215px',
  },
  btn: {
    marginTop: 55,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: "rgba(174, 255, 0, 0.15)",
    border: "none",
    outline: "none",
    color: "#000000",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: FontSize.size16,
    borderRadius: 4,
    cursor: "pointer",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "10px 20px",
      fontSize: 12,
      marginBottom: 0,
    },
  },
  defiTitle: {
    fontSize: FontSize.size25,
    marginTop: 21,
    fontWeight: "bold",
    lineHeight: 1.2,
    width: 330,
    textDecoration: "none",
    color: "#FFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      width: 195,
      paddingTop: 10,
      marginTop: 0,
    },
  },
  defiDescription: {
    fontSize: FontSize.m,
    color: theme.palette.secondary.textColor,
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size16,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      fontSize: 13,
    },
  },
  time: {
    fontSize: FontSize.sm,
    color: "#72757E",
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      marginTop: 10,
    },
  },
  paginationarea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 107,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 50,
    },
  },
  category: {
    padding: "15px 20px",
    borderRadius: 4,
    marginTop: 15,
    textAlign: 'center',
    maxWidth: 100,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "7px 13px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  pagination: {
    backgroundColor: "#16161A",
    color: "#72757E",
    margin: 10,
    outline: "none",
    border: "none",
    width: 45,
    height: 42,
    borderRadius: 4,
    cursor: "pointer",
  },
}));

const NewsContainer = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <MainNews data={news[0]} />
      <div className="container">
        <Grid spacing={2} container style={{xs: {paddingTop: 0}, sm: {paddingTop: 62}}}>
          {news.map((data, index) => (
              <NewsCard index={index} data={data}/>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default NewsContainer;
