import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PrivacyLeftSide from "./PrivacyLeftSide";
import FontSize from "../../../utils/FontSize";

import privacyFocusMainImg from "../../../assets/privacyfocused/Ghost_Privacy_Ecosystem_header.png";

import Font from "../../../utils/FontSize";

import crexsoftLogo from "../../../assets/exchangeLogos/crexsoft_logo.png";
import exchangeAssetsLogo from "../../../assets/exchangeLogos/exchangeassets_logo.png";
import ghostPirateLogo from "../../../assets/exchangeLogos/ghost_pirate_logo.png";
import nonkycLogo from "../../../assets/exchangeLogos/nonkyc_logo.png";
import tradeOgreLogo from "../../../assets/exchangeLogos/tradeogre_logo.png";
import uniswapLogo from "../../../assets/exchangeLogos/uniswap_logo.png";
import ivendpayLogo from "../../../assets/exchangeLogos/ivendpay_logo.png";
import specterLogo from "../../../assets/exchangeLogos/specter_systems_logo.png";

const useStyles = makeStyles((theme) => ({
  mainarea: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${privacyFocusMainImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 22% bottom 68%",
      backgroundSize: "30%",
    },
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${privacyFocusMainImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 25% bottom 60%",
      backgroundSize: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `none`,
    },
  },
  privacycontainer: {
    paddingTop: 70,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: 25,
    },
  },
  exchange: {
    height: 73,
    marginTop: "4em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "75px",
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      display: "flex",
      paddingBottom: "75px",
      marginRight: 0,
    },
  },
  exchangeText: {
    color: "#94A1B2",
    fontSize: Font.m,
    marginBottom: "15px",
    maxWidth: 510,
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size16,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: 13,
    },
  },
  industryTitle: {
    fontSize: FontSize.l,
    fontWeight: "bold",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size28,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2em",
      textAlign: "center",
    },
  },
  exchangeName: {
    maxWidth: "900px",
    minWidth: "370px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "470px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "370px",
    },
  },
  exchangeLogo: {
    marginRight: "25px",
    maxWidth: "125px",
    height: "auto",
    margin: "10px",
    [theme.breakpoints.down("700")]: {
      padding: "0px",
      width: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      padding: "5px",
      width: "115px",
      height: "auto",
    },
  },
  exchangeGroup: {
    margin: "10px",
  },
}));

const PrivacyFocused = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.mainarea}>
        <div className="container">
          <Grid container className={classes.privacycontainer}>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <PrivacyLeftSide />
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Grid item sm={12} xs={12}>
            <div className={classes.exchange}>
              <Typography className={classes.industryTitle}>
                Exchanges & Industry Partners
              </Typography>
              <Typography className={classes.exchangeText}>
                A selection of exchanges featuring Ghost as well as some of our
                valued partners from the Cryptocurrency and Security industries.
              </Typography>
              <div className={classes.exchangeName}>
                <div className={classes.exchangeGroup}>
                  <a
                    href="https://tradeogre.com/exchange/BTC-GHOST"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="TradeOgre"
                      src={tradeOgreLogo}
                    ></img>
                  </a>
                  <a
                    href="https://exchange-assets.com/en/?market=ghost_btc"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="Exchange Assets"
                      src={exchangeAssetsLogo}
                    ></img>
                  </a>
                  <a
                    href="https://crexsoft.com/en/trade/GHOST/btc"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="CrexSoft"
                      src={crexsoftLogo}
                    ></img>
                  </a>
                  <a
                    href="https://nonkyc.io/market/GHOST_BTC"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      style={{ top: "8px", position: "relative" }}
                      className={classes.exchangeLogo}
                      alt="NonKYC"
                      src={nonkycLogo}
                    ></img>
                  </a>
                </div>
                <div className={classes.exchangeGroup}>
                  <a
                    href="https://ghostpirate.biz/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="Ghost Pirate"
                      src={ghostPirateLogo}
                    ></img>
                  </a>
                  <a
                    href="https://app.uniswap.org/#/swap?outputCurrency=0xb5e0cfe1b4db501ac003b740665bf43192cc7853&chain=polygon"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="Uniswap"
                      src={uniswapLogo}
                    ></img>
                  </a>
                  <a
                    href="https://ivendpay.com/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="IvendPay"
                      src={ivendpayLogo}
                    ></img>
                  </a>
                  <a
                    href="https://gstvpn.com/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className={classes.exchangeLogo}
                      alt="Specter Systems"
                      src={specterLogo}
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PrivacyFocused;
