import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import UserGuideHeader from "./UserGuideHeader";
import UserContainer from "./UserContainer";
import userGuidebg from "../../assets/UserGuide/userGuidebg.svg";
import Footer from "../Footer";
import FooterDivider from "../../utils/FooterDivider";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden", // Hide overflowing content, including scrollbars
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${userGuidebg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
  },
}));

const data = [{
  title: "Joining a Staking Pool",
  description: "This is a quick guide on how you can join a Ghost staking pool to earn rewards on the Ghost network for staking your coins.",
  link: "https://ghostveterans.net/staking/",
}, {
  title: "VPS Cold Staking Setup",
  description: "Here you will find information about how you can create a cold staking VPS (virtual private machine) for the Ghost network.",
  link: "https://ghostveterans.net/vps/",
}, {
  title: "SHELTR Wallet User Guide",
  description: "This is the full getting started guide for the SHELTR wallet. It includes initial setup, staking setup and more.",
  link: "https://ghostveterans.net/sheltr/",
} , {
  title: "GhostPayBot User Guide",
  description: "The user guide for GhostPayBot. GhostPayBot is our on-chain Telegram Tip bot.",
  link: "https://ghostveterans.net/ghost-pay-bot/",
}];

const UserGuide = () => {
  const classes = useStyles();

  const [guides, setGuides] = useState(data);

  return (
    <div className={classes.container}>
      <UserGuideHeader updateGuides={setGuides} data={data}/>
      <UserContainer data={guides}/>
      <FooterDivider />
      <Footer />
    </div>
  );
};

export default UserGuide;

