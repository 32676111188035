import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ghostRoadMapImg from "../../assets/ghostRoadMap/ghostRoadMapImg.svg";
import Font from "../../utils/FontSize";
import RoadMapTabsQ1, {
  RoadMapTabsQ2,
  RoadMapTabsQ3,
  RoadMapTabsQ4,
  RoadMapTabsQ5,
  RoadMapTabsFut,
} from "./RoadMapTabs";
import RoadMapNav from "./RoadMapNav";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 26,
    paddingBottom: 100,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 26,
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  title: {
    fontSize: Font.l,
    fontWeight: "normal",
    fontFamily: "ProximaNovaBold",
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "auto",
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size25,
    },
  },
  title2: {
    fontSize: Font.l,
    lineHeight: 1.2,
    fontFamily: "ProximaNovaRegular",
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size25,
    },
  },
  ghostRoadMapImg: {
    width: 150,
    display: "block",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const RoadMapWrapper = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className="road-map-container">
        <div id="roadmap-title-container" className={classes.titleContainer}>
          <Typography className={classes.title}>
            Ghost Milestones
            <span className={classes.title2}> & Future Goals</span>
          </Typography>
          <RoadMapNav />
        </div>

        <div id="2020-wrapper" className="road-map-container">
          <div id="spacer" className="spacer"></div>
          <h1 id="2020" className="hide-wide">
            2020
          </h1>
          <div className="hide-narrow" style={{ height: "24px" }}></div>
          <RoadMapTabsQ1 />
        </div>

        <div id="2021-wrapper" className="hide-wide road-map-container">
          <h1 id="2021" className="hide-wide">
            2021
          </h1>
          <div className="hide-narrow" style={{ height: "24px" }}></div>
          <RoadMapTabsQ2 />
        </div>

        <div id="2022-wrapper" className="hide-wide road-map-container">
          <h1 id="2022" className="hide-wide">
            2022
          </h1>
          <div className="hide-narrow" style={{ height: "24px" }}></div>
          <RoadMapTabsQ3 />
        </div>

        <div id="2023-wrapper" className="hide-wide road-map-container">
          <h1 id="2023" className="hide-wide">
            2023
          </h1>
          <div className="hide-narrow" style={{ height: "24px" }}></div>
          <RoadMapTabsQ4 />
        </div>
        <div id="2024-wrapper" className="hide-wide road-map-container">
          <h1 id="2024" className="hide-wide">
            2024
          </h1>
          <div className="hide-narrow" style={{ height: "24px" }}></div>
          <RoadMapTabsQ5 />
        </div>
        <div id="future-wrapper" className="hide-wide road-map-container">
          <h1 id="future" className="hide-wide">
            Future
          </h1>
          <div className="hide-narrow" style={{ height: "48px" }}></div>
          <RoadMapTabsFut />
        </div>
      </div>
    </div>
  );
};

export default RoadMapWrapper;
