import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FontSize from "../../utils/FontSize";
import whitepaper from "../../assets/whitepaper/Ghost_by_McAfee_Whitepaper.pdf";
import { Link } from "react-router-dom";
import logo from "../../assets/ghostlogo-official.png";
import SocialIcon from "../../utils/SocialIcon";
import DiscordIcon from "../../assets/privacyfocused/discord.svg";
import GoogleIcon from "../../assets/google-play-badge.png";

const useStyles = makeStyles((theme) => ({
  navContainer: {
    margin: "auto",
    maxWidth: 1110,
    minHeight: 80,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      padding: "0 10px",
    },
  },
  logoArea: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: 150,
  },
  logoText: {
    marginLeft: 17,
    fontSize: FontSize.size25,
  },
  description: {
    width: 170,
    fontSize: FontSize.size16,
    color: "rgba(255, 255, 255, 0.6)",
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("s")]: {
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  title: {
    width: 160,
  },
  navbarText: {
    color: "rgba(255, 255, 255, 0.6)",
    textDecoration: "none",
    paddingTop: 10,
  },
  footerInner: {
    width: 200,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      width: 400,
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <div style={{ ...props.style }}>
      <div className={classes.navContainer}>
        <Grid container align="center" justify="center" direction="row">
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <div className={classes.logoArea}>
                <img alt="Ghost Logo" src={logo} className={classes.logo} />
              </div>
              <Typography className={classes.description}>
                Privacy focused proof of stake cryptocurrency
                <Typography>
                  <a
                    style={{ color: "#ffffff" }}
                    href="mailto:Contact@GhostbyJohnMcAfee.com"
                    className="green-hover"
                  >
                    Contact@GhostbyJohnMcAfee.com
                  </a>
                </Typography>
              </Typography>
              <Typography className={classes.title}>GHOST Community</Typography>
              <div className={classes.social}>
                <SocialIcon DiscordIcon={DiscordIcon} compact={true} />
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=org.ghost.ghostelectrum">
                  <img
                    alt="Get it on Google Play"
                    src={GoogleIcon}
                    height="50"
                  />
                </a>
                <pre>
                  <font size="1">
                    Google Play and the Google Play logo are trademarks of
                    Google LLC.
                  </font>
                </pre>
              </div>
            </div>
            <br />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>
                GHOST Starter pack
              </Typography>
              <div className={classes.nav}>
                <a
                  href="/news"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Latest News</Typography>
                </a>
                <a
                  href="https://coldstaking.pro"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Status Report</Typography>
                </a>
                <a
                  href="https://app.sheltrwallet.com"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">SHELTR wallet</Typography>
                </a>
                <a
                  href="https://secret.ghostbyjohnmcafee.com/"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Ghost Secret</Typography>
                </a>
                <a
                  href="https://ghostveterans.net/buy/"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Get GHOST</Typography>
                </a>
                <a
                  href="https://ghostveterans.net/"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Ghost Docs</Typography>
                </a>
              </div>
              <br />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>Trade GHOST on</Typography>
              <div className={classes.nav}>
                <a
                  href="https://tradeogre.com/exchange/BTC-GHOST"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">TradeOgre</Typography>
                </a>
                <a
                  href="https://exchange-assets.com/en/?market=ghost_btc"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">
                    Exchange Assets
                  </Typography>
                </a>
                <a
                  href="https://crexsoft.com/en/trade/GHOST/btc"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">CrexSoft</Typography>
                </a>
                <a
                  href="https://nonkyc.io/market/GHOST_BTC"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">NonKYC</Typography>
                </a>
                <a
                  href="https://ghostpirate.biz/"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Ghost Pirate</Typography>
                </a>
                <a
                  href="https://app.uniswap.org/#/swap?outputCurrency=0xb5e0cfe1b4db501ac003b740665bf43192cc7853&chain=polygon"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Uniswap</Typography>
                </a>
              </div>
              <br />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className={classes.footerInner}>
              <Typography className={classes.title}>
                Join the movement
              </Typography>
              <div className={classes.nav}>
                <a
                  href="https://ghostscan.io"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">
                    Block Explorer
                  </Typography>
                </a>
                <a
                  href="https://explorer.myghost.org"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">
                    Block Explorer 2
                  </Typography>
                </a>
                <a
                  href="https://github.com/ghost-coin"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Github</Typography>
                </a>
                <a
                  href={whitepaper}
                  rel="noreferrer"
                  target="_blank"
                  className={classes.navbarText}
                >
                  <Typography className="green-hover">Whitepaper</Typography>
                </a>
                <Link to="/userguide" className={classes.navbarText}>
                  <Typography className="green-hover">User Guides</Typography>
                </Link>
                <Link to="/faq" className={classes.navbarText}>
                  <Typography className="green-hover">FAQ</Typography>
                </Link>
              </div>
              <br />
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
};

export default Footer;
