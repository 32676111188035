import React from "react";
import Footer from "../Footer";
import FooterDivider from "../../utils/FooterDivider";
import FaqGeneral from "./FaqGeneral";
import {Button, Grid, Typography} from "@material-ui/core";
import {Link, Route, Switch} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import FontSize from "../../utils/FontSize";
import FaqStaking from "./FaqStaking";
import withRouter from "react-router-dom/es/withRouter";
import FaqVeteran from "./FaqVeteran";
import FaqExchange from "./FaqExchange";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 70,
        [theme.breakpoints.down("xs")]: {
            paddingTop: 70,
        },
    },
    title: {
        fontSize: FontSize.l,
        fontWeight: "bold",
        lineHeight: 1.2,
        [theme.breakpoints.down("md")]: {
            fontSize: FontSize.size40,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: FontSize.size40,
            width: 340,
        },
    },
    btn: {
        paddingTop: 53,
        paddingBottom: 70,
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 50,
        },
    },
    button: {
        fontWeight: 300,
        marginRight: 25,
        cursor: "pointer",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            marginBottom: 15,
        },
    },
    subtitle: {
        fontSize: FontSize.size25,
        fontWeight: "bold",
        lineHeight: 1.2,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -9,
        },
    },
    description: {
        fontSize: FontSize.m,
        color: theme.palette.secondary.textColor,
        paddingTop: 22,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -13,
            width: 355
        },
    },
    section: {
        paddingRight: 70,
        paddingBottom: 70
    }
}));

export const FaqContainer = (props) => {
    const classes = useStyles();
    const { title, children } = props;
    return <Grid item sm={12} xs={12} md={6} className={classes.section}>
        <Typography className={classes.subtitle}>{title}</Typography>
        <Typography className={classes.description}>
            {children}
        </Typography>
    </Grid>
}

const FaqMenuButton = ({ title, base, location }) => {
    const classes = useStyles();
    if (location.pathname === base) {
        return <Link className="routerlink" to={base}>
            <Button variant="contained" color="secondary" className={classes.button}>{title}</Button>
        </Link>
    }
    return <Link className="routerlink" to={base}>
        <Button color="inherit" className={classes.button} style={{ opacity: 0.5 }}>
            {title}
        </Button>
    </Link>
}

const Faq = (props) => {
  const classes = useStyles();
  const { location } = props;
  return (
      <div className="container">
          <div className={classes.container}>
              <Typography className={classes.title}>
                Frequently Asked Questions
              </Typography>
              <div className={classes.btn}>
                <FaqMenuButton title="General" base="/faq" location={location} />
                <FaqMenuButton title="Staking" base="/faq/staking" location={location} />
                <FaqMenuButton title="Ghost Veterans" base="/faq/veterans" location={location} />
                <FaqMenuButton title="Exchanges / Wallets" base="/faq/exchange" location={location} />
              </div>
              <Switch>
                <Route exact component={FaqGeneral} path="/faq" />
                <Route exact component={FaqStaking} path="/faq/staking" />
                <Route exact component={FaqVeteran} path="/faq/veterans" />
                <Route exact component={FaqExchange} path="/faq/exchange" />
              </Switch>
              <FooterDivider />
              <Footer />
          </div>
    </div>
  );
};

export default withRouter(Faq);
