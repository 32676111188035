import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Font from "../../utils/FontSize";
import { CsvToHtmlTable } from "react-csv-to-table";
import { GHOST_CURRENT_STATE } from "../../Ghost";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 26,
    display: "flex",
    margin: "20px",
    justifyContent: "center",
    color: "#fafafa",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 26,
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: Font.l,
    minWidth: "100vw",
    fontWeight: "normal",
    marginBottom: "30px",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: Font.size40,
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Font.size28,
      minWidth: "unset",
    },
  },
  tableContainer: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "fit-content",
    },
  },
  highlightRow: {
    border: "thin solid #aeff00",
    backgroundColor: "#aeff00",
    borderRadius: "10px !important",
  },

  highlightData: {
    color: "#232728 !important",
    fontWeight: "bold",
    backgroundColor: "#aeff00",
  },

  radiusTR: {
    borderRadius: "0 6px 0 0",
  },

  radiusTL: {
    borderRadius: "6px 0 0 0",
  },

  radiusBR: {
    borderRadius: "0 0 6px 0",
  },

  radiusBL: {
    borderRadius: "0 0 0 6px",
  },

  radiusL: {
    borderRadius: "6px 0 0 6px",
  },
  radiusR: {
    borderRadius: "0 6px 6px 0",
  },

  mainTableContainer: {
    overflowX: "auto",
    minWidth: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  btnCommon: {
    width: "196px",
    height: "48px",
    borderRadius: 8,
    textTransform: "capitalize",
    margin: "35px",
    paddingLeft: "5px",
    paddingRight: "5px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "36px",
      borderRadius: 6,
      fontSize: Font.size12,
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px",
      height: "24px",
      borderRadius: 5,
      fontSize: Font.size10,
    },
  },
  downloadBtn: {
    border: "1px solid #FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.15)",
    },
  },

  borderDiv: {
    border: "thin solid #fafafa",
    padding: "5px",
    color: "#fafafa",
    borderRadius: 6,
    minWidth: "10ch",
    display: "relative",
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.down("xs")]: {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
}));

const sampleData = `
Year,Start Date,nBlockPerc,% decrease,Block reward,Emission,Staker,AGVR,Dev Fund
Year 1,06/22/20,100,0,12,16856615,4.08,6,1.92
Year 2,06/22/21,100,0,12,20010215,4.08,6,1.92
Year 3,06/22/22,95,0.05,11.4,23006135,3.876,5.7,1.824
Year 4,06/22/23,90,0.0526,10.8,24692965.4,3.672,5.4,1.728
Year 4,03/20/24,90,0.0444,8.1,25556522.6,3.726,2.673,1.701
Year 5,06/22/24,86,0.0444,7.74,27590594.6,3.5604,2.5542,1.6254
Year 6,06/22/25,81,0.0581,7.29,29506406.6,3.3534,2.4057,1.5309
Year 7,06/22/26,77,0.0494,6.93,31327610.6,3.1878,2.2869,1.4553
Year 8,06/22/27,74,0.039,6.66,33077858.6,3.0636,2.1978,1.3986
Year 9,06/22/28,70,0.0541,6.3,34733498.6,2.898,2.079,1.323
Year 10,06/22/29,66,0.0571,5.94,36294530.6,2.7324,1.9602,1.2474
Year 11,06/22/30,63,0.0455,5.67,37784606.6,2.6082,1.8711,1.1907
Year 12,06/22/31,60,0.0476,5.4,39203726.6,2.484,1.782,1.134
Year 13,06/22/32,57,0.05,5.13,40551890.6,2.3598,1.6929,1.0773
Year 14,06/22/33,54,0.0526,4.86,41829098.6,2.2356,1.6038,1.0206
Year 15,06/22/34,51,0.0556,4.59,43035350.6,2.1114,1.5147,0.9639
Year 16,06/22/35,49,0.0392,4.41,44194298.6,2.0286,1.4553,0.9261
Year 17,06/22/36,46,0.0612,4.14,45282290.6,1.9044,1.3662,0.8694
Year 18,06/22/37,44,0.0435,3.96,46322978.6,1.8216,1.3068,0.8316
Year 19,06/22/38,42,0.0455,3.78,47316362.6,1.7388,1.2474,0.7938
Year 20,06/22/39,40,0.0476,3.6,48262442.6,1.656,1.188,0.756
Year 21,06/22/40,38,0.05,3.42,49161218.6,1.5732,1.1286,0.7182
Year 22,06/22/41,36,0.0526,3.24,50012690.6,1.4904,1.0692,0.6804
Year 23,06/22/42,34,0.0556,3.06,50816858.6,1.4076,1.0098,0.6426
Year 24,06/22/43,32,0.0588,2.88,51573722.6,1.3248,0.9504,0.6048
Year 25,06/22/44,31,0.0313,2.79,52306934.6,1.2834,0.9207,0.5859
Year 26,06/22/45,29,0.0645,2.61,52992842.6,1.2006,0.8613,0.5481
Year 27,06/22/46,28,0.0345,2.52,53655098.6,1.1592,0.8316,0.5292
Year 28,06/22/47,26,0.0714,2.34,54270050.6,1.0764,0.7722,0.4914
Year 29,06/22/48,25,0.0385,2.25,54861350.6,1.035,0.7425,0.4725
Year 30,06/22/49,10,0,0.9,55097870.6,0.414,0.297,0.189
Year 31,06/22/50,10,0,0.9,55334390.6,0.414,0.297,0.189
Year 32,06/22/51,10,0,0.9,55570910.6,0.414,0.297,0.189
Year 33,06/22/52,10,0,0.9,55807430.6,0.414,0.297,0.189
Year 34,06/22/53,10,0,0.9,56043950.6,0.414,0.297,0.189
Year 35,06/22/54,10,0,0.9,56280470.6,0.414,0.297,0.189
Year 36,06/22/55,10,0,0.9,56516990.6,0.414,0.297,0.189
Year 37,06/22/56,10,0,0.9,56753510.6,0.414,0.297,0.189
Year 38,06/22/57,10,0,0.9,56990030.6,0.414,0.297,0.189
Year 39,06/22/58,10,0,0.9,57226550.6,0.414,0.297,0.189
Year 40,06/22/59,10,0,0.9,57463070.6,0.414,0.297,0.189
Year 41,06/22/60,10,0,0.9,57699590.6,0.414,0.297,0.189
Year 42,06/22/61,10,0,0.9,57936110.6,0.414,0.297,0.189
Year 43,06/22/62,10,0,0.9,58172630.6,0.414,0.297,0.189
Year 44,06/22/63,10,0,0.9,58409150.6,0.414,0.297,0.189
Year 45,06/22/64,10,0,0.9,58645670.6,0.414,0.297,0.189
Year 46,06/22/65,10,0,0.9,58882190.6,0.414,0.297,0.189
Year 47,06/22/66,10,0,0.9,59118710.6,0.414,0.297,0.189
`;

const EmissionsWrapper = () => {
  const classes = useStyles();

  useEffect(() => {
    // Log information and add a class after the table is rendered in the DOM
    const cells = document.querySelectorAll(".emissions td");
    const head = document.querySelectorAll(".emissions th");
    let first_child = true;

    head.forEach((header) => {
      header.innerHTML = `<div class="${classes.borderDiv}">${header.textContent}</div>`;
    });

    cells.forEach((cell, index) => {
      const cellValue = cell.textContent;

      // Get the parent row (tr) of the cell
      const parentRow = cell.closest("tr");

      const currentYear = GHOST_CURRENT_STATE.currentYear;
      const children = parentRow.querySelectorAll("*");

      if (cellValue === `Year ${currentYear}`) {
        if (currentYear == 4) {
          if (first_child) {
            parentRow.firstChild.classList.add(classes.radiusTL);
            parentRow.lastChild.classList.add(classes.radiusTR);
            first_child = false;
          } else {
            parentRow.firstChild.classList.add(classes.radiusBL);
            parentRow.lastChild.classList.add(classes.radiusBR);
          }
        } else {
          parentRow.firstChild.classList.add(classes.radiusL);
          parentRow.lastChild.classList.add(classes.radiusR);
        }
        children.forEach((child) => {
          child.classList.add(classes.highlightData);
        });
      }
    });
  }, [classes.highlightRow]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          <span style={{ fontFamily: "ProximaNovaBold" }}>Ghost</span> Emissions
        </Typography>
      </div>
      <div className={classes.mainTableContainer}>
        <Typography>
          <CsvToHtmlTable
            tableClassName="emissions"
            data={sampleData}
            csvDelimiter=","
            className={classes.tableContainer}
          />
        </Typography>
      </div>
    </div>
  );
};

export default EmissionsWrapper;
