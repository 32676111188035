const news = [
    {
        title: "Introducing: Wrapped Ghost on Polygon (Aug - 2023)",
        descr: "Wrapped Ghost on Polygon is now live!",
        date: "Aug 10–2023",
        image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*-KZ2OL8jhLl9EIek0Hl8ig.png",
        link: "https://ghostbymcafee.medium.com/introducing-wrapped-ghost-on-polygon-8c264bff5caf",
        type: 'News'
    },
    {
        title: "Ghost Development Update (Jul - 2023)",
        descr: "With the first six months behind us, we have maintained our focused on executing roadmap deliverables and also pushed out a few maintenance updates along the way. Inside this update we will be discussing our progress on the Polygon Bridge, Core maintenance, On-Chain Governance, Community events, and other noteworthy developments.",
        date: "Jul 21–2023",
        image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*4WAMEZ-QmBEdCYxbms8ycA.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-first-half-of-2023-c0489076acd0",
        type: 'Update'
    },
    {
        title: "Ghost + ivendPay Partnership (Apr – 2023)",
        descr: "We are very happy to announce our reintegration with IvendPay! ivendPay is a payment gateway that specializes in providing cryptocurrency payment solutions for retail, e-commerce, and vending machines.",
        date: "Apr 20–2023",
        image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*u42UWM4EDyTkQ7Wa-WUwhA.png",
        link: "https://ghostbymcafee.medium.com/ghost-ivendpay-partnership-60fe03fae0cf",
        type: 'News'
    },
    {
        title: "SHELTR: The Ultimate Self-Custody Wallet for Ghost (Apr – 2023)",
        descr: "We are very excited to announce the release of Sheltr, a new mobile wallet powering the next generation of Ghost’s ecosystem. Visit https://app.sheltrwallet.com to upgrade your Ghost experience now!",
        date: "Apr 14–2023",
        image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*xtX4izTapdS_Rdulj7Sd3w.png",
        link: "https://ghostbymcafee.medium.com/sheltr-the-ultimate-self-custody-wallet-for-ghost-c83f2adb60e2",
        type: 'News'
    },
    {
        title: "Ghost EOY Recap (Jan – 2023)",
        descr: "In this article we are taking a look back over the past year’s historic developments, notable community updates, some of the challenges the team faced and how we overcame them, and the overall progress to date.",
        date: "Jan 03–2023",
        image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*UMnPX9AYOHpXNmknmyFEIw.png",
        link: "https://ghostbymcafee.medium.com/ghost-eoy-recap-c11176f7871f",
        type: 'News'
    },
    {
        title: "Ghost Development Update (Oct – 2022)",
        descr: "For Q3, our developers continued to push out a number of developments and exceed milestones, with one deliverable that’s for the history books.",
        date: "Oct 25–2022",
        image: "https://miro.medium.com/max/720/1*Yv1ARYjCb62yiFBfZcZrog.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-october-2022-fcb82720ec6",
        type: 'Update'
    },
    {
        title: "New Ghost Core Update (Sep – 2022)",
        descr: "After months of development and extensive rounds of testing, we are very excited to announce that development for Automated Ghost Veteran Rewards (AGVR) is now complete and ready for production!",
        date: "Sep 19–2022",
        image: "https://miro.medium.com/max/700/1*82eDSmsKzDD16EVYFToVBQ.png",
        link: "https://ghostbymcafee.medium.com/automated-ghost-veteran-rewards-31da40bb906e",
        type: 'News'
    },
    {
        title: "Ghost Development Update (July – 2022)",
        descr: "For Q2 we kept the same momentum as the previous quarter. Development has been non-stop and researching has lead to some great discoveries for upcoming deliverables.",
        date: "Jul 21–2022",
        image: "https://miro.medium.com/max/700/1*Yv1ARYjCb62yiFBfZcZrog.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-july-2022-622eaaf5f61e",
        type: 'Update'
    },
    {
        title: "Ghost Development Update (May – 2022)",
        descr: "After Q1’s massive round of updates, we have been staying focused on developing more of our deliverables that will expand the Ghost ecosystem.",
        date: "May 25–2022",
        image: "https://miro.medium.com/max/700/1*Yv1ARYjCb62yiFBfZcZrog.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-may-2022-24fe8a2cf90d",
        type: 'Update'
    },
    {
        title: "GhostVault: The New Era of Staking (April – 2022)",
        descr: "In the past when it came to self hosted cold staking nodes, Ghost users would spin up a new node through their own virtual private server using the protocol called Ghostman.",
        date: "Apr 05–2022",
        image: "https://miro.medium.com/max/700/1*-4kS-Hofacpzv7pZ68i5cw.png",
        link: "https://ghostbymcafee.medium.com/ghostvault-the-new-era-of-staking-44bca7d3da07",
        type: 'News'
    },
    {
        title: "Ghost EOM Update (March – 2022)",
        descr: "On March 9th we released core version 0.21.1.8 that laid out the foundation for what was months long worth of work.",
        date: "Mar 31–2022",
        image: "https://miro.medium.com/max/700/1*EeldnYytBUmRawZo6h4DfA.png",
        link: "https://ghostbymcafee.medium.com/ghost-eom-update-march-31-356328b66a24",
        type: 'Update'
    },
    {
        title: "New Ghost Core Update (March – 2022)",
        descr: "At block height 458743 the Ghost blockchain underwent a planned hard fork. This is in preparation for the upcoming development on automating Ghost Veteran Rewards...",
        date: "Mar 31–2022",
        image: "https://miro.medium.com/max/700/1*JyBFi_allDmXWGrpUNzhjQ.png",
        link: "https://ghostbymcafee.medium.com/new-ghost-core-update-8757548bec4c",
        type: 'News'
    },
    {
        title: "Ghost Development Update (March – 2022)",
        descr: "Back in September when we hard forked to re-enable anonymous transactions we included an additional privacy layer to the Ghost blockchain.",
        date: "Mar 04–2022",
        image: "https://miro.medium.com/max/700/1*vZsHcroKHXhsms9w6LJQOg.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-march-2022-46e1103ea4af",
        type: 'Update'
    },
    {
        title: "Staking on StakeCube now Live (Dec 20–2022)",
        descr: "After a few months of working with the team over at StakeCube , we are happy to announce that support for staking $GHOST is now live!",
        date: "Dec 20–2021",
        image: "https://miro.medium.com/max/700/1*-GBFCH03rG2a_xxxdoChkQ.png",
        link: "https://ghostbymcafee.medium.com/staking-on-stakecube-now-live-ad723bf5e459",
        type: 'News'
    },
    {
        title: "Fiat On-Ramp (Dec 09–2022)",
        descr: "On December 6, the GHOST Team launched the first direct fiat exchange service for GHOST. Meet GhostPirate.biz!",
        date: "Dec 09–2021",
        image: "https://miro.medium.com/max/700/1*NcWp_4tTvvuXZostZSkv7A.png",
        link: "https://ghostbymcafee.medium.com/fiat-on-ramp-bc1082b6d0d1",
        type: 'Swap'
    },
    {
        title: "Wrapped $GHOST Development (Nov 03–2021)",
        descr: "The Ghost Team has now started development for Wrapped Ghost with cooperation from Poltergiest Labs.",
        date: "Nov 03–2021",
        image: "https://miro.medium.com/max/700/1*DnRZhFfSUHBQNJjCcW-nBQ.png",
        link: "https://ghostbymcafee.medium.com/wrapped-ghost-development-2f3d69ad1ad3",
        type: 'Swap'
    },
    {
        title: "Ghost listed on P2PB2B Exchange (Sept 09–2021)",
        descr: "Trading for $GHOST is now live on P2PB2B! P2PB2B is an advanced cryptocurrency exchange that works for the benefit of its users.",
        date: "Sept 09–2021",
        image: "https://miro.medium.com/max/700/1*AsrQk6PEav-QIb1B9a7bhw.png",
        link: "https://ghostbymcafee.medium.com/?p=77b16e8ab48d",
        type: 'News'
    },
    {
        title: "Ghost Community Job Board (Sept 09–2021)",
        descr: "Help Ghost complete it’s mission one step at a time. Contribute your talents to the Ghost community and be rewarded for your commitment.",
        date: "Sept 09–2021",
        image: "https://miro.medium.com/max/700/1*uduADt6ZFN3rcrUS86TyzQ.png",
        link: "https://ghostbymcafee.medium.com/ghost-community-job-board-945f4d0bab90",
        type: 'News'
    },
    {
        title: "Ghost Development Update (Sept 04–2021)",
        descr: "Thank you all for your patience during this time. The Ghost team has worked long sleepless hours sorting out post fork issues.",
        date: "Sept 04–2021",
        image: "https://miro.medium.com/max/700/1*ul_AYONcYFWp5NGL7dlViw.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-september-4-2021-91b89110263",
        type: 'Update'
    },
    {
        title: "Ghost Development Update (Aug 11–2021)",
        descr: "Over the past six months+ the Ghost team has been hard at work to progress the project forward and meet road map deliverables.",
        date: "Aug 11–2021",
        image: "https://miro.medium.com/max/700/1*LsU89ntphFOEavb1utFN2g.png",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-ytd-august-2021-fc4cdb1e7422",
        type: 'Update'
    },
    {
        title: "Ghosts n’ NFTs (June 23–2021)",
        descr: "Ghost has officially created an account on Opensea.io",
        date: "June 23, 2021",
        image: "https://miro.medium.com/max/700/1*Ku8MJptOCPb7IO1t0ahr0Q.png",
        link: "https://ghostbymcafee.medium.com/ghosts-n-nfts-1797ca278a64",
        type: 'News'
    },
    {
        title: "The Eco-Friendly Crypto (June 01–2021)",
        descr: "Do your part for the environment and shift to cleantech blockchain solutions like Ghost.",
        date: "June 01, 2021",
        image: "https://miro.medium.com/max/2560/1*qYXW2tTc1KBQ5PozAqKw-A.png",
        link: "https://ghostbymcafee.medium.com/the-eco-friendly-crypto-d6fccf467cf8",
        type: 'News'
    },
    {
        title: "Ghost listed on Hebe Wallet (May 14–2021)",
        descr: "Hebe Wallet has listed $GHOST on its mobile blockchain wallet. The app is available on Android/iOS devices.",
        date: "May 14, 2021",
        image: "https://miro.medium.com/max/2246/1*p9H6GMvPw22yZUjPAilGFw.png",
        link: "https://ghostbymcafee.medium.com/ghost-listed-on-hebe-wallet-7d239461572e",
        type: 'News'
    },
    {
        title: "Ghost listed on StakeCube (May 07–2021)",
        descr: "At current press time, Ghost is now live on StakeCube. Users can use their service to store their coins, and buy/sell through StakeCube’s exchange.",
        date: "May 07, 2021",
        image: "https://miro.medium.com/max/1984/1*gaonOIKHaki7dIRs1LznsA.png",
        link: "https://ghostbymcafee.medium.com/ghost-listed-on-stakecube-ebf3b7a75cba",
        type: 'News'
    },
    {
        title: "Emergency Hardfork (March 04–2021)",
        descr: "The GHOST team has effectively secured the potential threat that required a hardfork. Please update your desktop wallet",
        date: "March 11, 2021",
        image: "https://cdn.discordapp.com/attachments/797283812371333150/819456016731209728/updatesu.jpg",
        link: "https://ghostveterans.net/hardfork-update/",
        type: 'Emergency'
    },
    {
        title: "Emergency Hardfork (March 04–2021)",
        descr: "GHOST team has found a critical issue which requires an emergency hardfork. Please update your nodes and desktop wallet!",
        date: "March 04, 2021",
        image: "https://cdn.discordapp.com/attachments/797283812371333150/819453545740238858/ehf.jpg",
        link: "https://ghostveterans.net/emergency-hardfork",
        type: 'Emergency'
    },
    {
        title: "A Private Stable Coin on Ghost! (March 03–2021)",
        descr: "Poltergeist Exchange Partners With GHOST By McAfee To Release A Private Stable Coin and Privacy Exchange On Ghost!",
        date: "March 03, 2021",
        image: "https://cdn.discordapp.com/attachments/797283812371333150/819452144100048907/ed.png",
        link: "https://poltergeistexchange.medium.com/poltergeist-exchange-partners-with-ghost-by-mcafee-to-release-a-private-stable-coin-and-privacy-714af252188e",
        type: 'Ghoul'
    },
    {
        title: "Ghost ERC20 to Ghost Main-Net (March 01–2021)",
        descr: "The final deadline for swapping the Ghost ERC20 Token to the official main-net coin has almost arrived.",
        date: "March 01, 2021",
        image: "https://secureservercdn.net/104.238.68.196/y50.be9.myftpupload.com/wp-content/uploads/2021/02/last-chance-1024x576.jpg",
        link: "https://ghostveterans.net/erc20-swap/",
        type: 'Swap'
    },
    {
        title: "The Case for Dandelion (February 23–2021)",
        descr: "Privacy, once a hallmark of Bitcoin’s code, has become pseudonymous and not anonymous.",
        date: "February 23, 2021",
        image: "https://miro.medium.com/max/700/1*X07clkK0u6Eg0_3elw-QaA.jpeg",
        link: "https://ghostbymcafee.medium.com/the-case-for-dandelion-bd37f2fb0771",
        type: 'Privacy'
    },
    {
        title: "Ghost Core & Wallet Update (February 22–2021)",
        descr: "At block height 140536, Ghost successfully upgraded its core to 0.19.1.9 and the Ghost desktop wallet to 2.0.8.",
        date: "February 22, 2021",
        image: "https://miro.medium.com/max/700/1*HKlYaFV7fKLG0HMTgv22gw.jpeg",
        link: "https://ghostbymcafee.medium.com/ghost-core-wallet-update-2ad659081447",
        type: 'Update'
    },
    {
        title: "Ghost Core Update (January 8–2021)",
        descr: "Core & Desktop Update",
        date: "January 8, 2021",
        image: "https://secureservercdn.net/104.238.68.196/y50.be9.myftpupload.com/wp-content/uploads/2021/01/ghostupdate.jpg",
        link: "https://ghostveterans.net/update108/",
        type: 'Update'
    }, {
        title: "Ghost Community Update (October 30–2020)",
        descr: "Frequent updates to keeps the community informed about the latest developments",
        date: "October 30, 2020",
        image: "https://miro.medium.com/max/1400/1*0Ta5iJ4kp3MYRFP7j7_RJw.png",
        link: "https://ghostbymcafee.medium.com/ghost-community-update-f4efdecdb40",
        type: 'Community'
    }, {
        title: "GhostX DEX Launch (October 15–2020)",
        descr: "GhostX.live Decentralized Atomic Swap Exchange",
        date: "October 15, 2020",
        image: "https://miro.medium.com/max/1400/1*KZz5f0OVzlEstQmDQQNWBQ.jpeg",
        link: "https://ghostbymcafee.medium.com/ghostx-atomic-swap-exchange-announcement-772a875b1b31",
        type: 'News'
    }, {
        title: "Ghost Development Update (July 31–2020)",
        descr: "Ghost Core v0.19.2.0 Network, Fixes, and Major Improvements",
        date: "July 31, 2020",
        image: "https://miro.medium.com/max/700/1*5oi1PATfgr6gMNqhlFlVFg.jpeg",
        link: "https://ghostbymcafee.medium.com/ghost-development-update-july-31-2020-531d3e332b01",
        type: 'Update'
    }, {
        title: "Ghost can now be accepted on Major eCommerce Platforms",
        descr: "A partnership with Crypto Currency Checkout allows for Ghost to be accepted at popular eCommerce platforms such as Shopify, WooCommerce, BigCommerce and...",
        date: "July 21, 2020",
        image: "https://miro.medium.com/max/700/1*zNpYSzDYmgS7KnOhJKQuFQ.jpeg",
        link: "https://medium.com/@GhostbyMcAfee/ghost-can-now-be-accepted-on-major-ecommerce-platforms-924d4c573286",
        type: 'News'
    }, {
        title: "GVR (Ghost Veteran Rewards) Announcement",
        descr: "Hey everyone! First thing, the Ghost Veteran payout schedule is based on a 30 day cycle. The wallet and ERC-20 swap portal was officially released 25 days ago...",
        date: "July 20, 2020",
        image: "https://miro.medium.com/max/700/1*TSDxRDLWazg90nKHKWwJ7Q.jpeg",
        link: "https://ghostveterans.net/veterans/",
        type: 'News'
    }, {
        title: "Ghost — Big Updates (07/17/20)",
        descr: "First, want to say thank you each member in the Ghost community. Your support allows us to continue building great things for the future. We are here for the long term...",
        date: "July 17, 2020",
        image: "https://miro.medium.com/max/400/0*Zx7-BSI_yQg4MOKG.jpg",
        link: "https://ghostbymcafee.medium.com/ghost-update-07-17-20-4c2548971861",
        type: 'Update'
    }, {
        title: "GHOST Devs AMA Recap — 07/13",
        descr: "Q1) What do you think about privacy coins in general? I mean all those other coins like monero, zcash, pivx and so on have failed to be mass adopted...",
        date: "July 13, 2020",
        image: "https://miro.medium.com/max/700/1*Bic1Fbn2cV_NGyNQay-H-A.jpeg",
        link: "https://t.me/ghostcoinbymcafee",
        type: 'News'
    }, {
        title: "Ghost Recap - How far we've come",
        descr: "We wanted to put together a timeline in order to showcase the last 45 days. This timeline brings to light the full progression of Ghost and what we’ve been able to achieve thus far...",
        date: "July 9, 2020",
        image: "https://miro.medium.com/max/700/1*K1VGwnPF1YrmsFlh70P52Q.jpeg",
        link: "https://ghostbymcafee.medium.com/ghost-recap-how-far-weve-come-b35b9bcb80d7",
        type: 'News'
    },
];

export default news;
