import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, CardMedia } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import RedditIcon from "@material-ui/icons/Reddit";
import TelegramIcon from "@material-ui/icons/Telegram";

const useStyles = makeStyles((theme) => ({
    discordIcon: {
        height: 22,
        width: 25,
      },
      socialIconGroup: {
        marginTop: 55,
      },
      socialIcon: {
        color: theme.palette.secondary.socialIconColor,
        fontSize: 25,
      },
}));

const SocialIcon = (props) => {
  const classes = useStyles();
  const buttonPadding = props.compact ? 0 : 12;
  return (
    <div>
      <a href = "https://twitter.com/Ghost_Privacy" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding, paddingLeft: buttonPadding}}>
          <TwitterIcon className={classes.socialIcon} />
        </IconButton>
      </a>
      <a href = "https://discord.gg/Pjbme6v" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <CardMedia
            className={classes.discordIcon}
            image={props.DiscordIcon}
            title="DiscordLogo"
          />
        </IconButton>
      </a>
      <a href = "https://www.reddit.com/r/GhostPrivacyCoin/" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <RedditIcon className={classes.socialIcon} />
        </IconButton>
      </a>
      <a href = "https://t.me/ghostcoinbymcafee" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <TelegramIcon className={classes.socialIcon} />
        </IconButton>
      </a>

      <a href = "https://ghostprivacy.medium.com/" rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
        <IconButton style={{paddingRight: buttonPadding}}>
          <svg 
                xmlns="http://www.w3.org/2000/svg"
                shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision"
                image-rendering="optimizeQuality"
                fill-rule="evenodd"
                clip-rule="evenodd"
                width="24"
                height="24"
                viewBox="0 0 512 290.78"
                >
                  <path fill-rule="nonzero" d="M288.798 145.387c0 80.297-64.65 145.392-144.401 145.392C64.645 290.779 0 225.703 0 145.387 0 65.072 64.65 0 144.397 0c79.747 0 144.401 65.091 144.401 145.387zm158.41 0c0 75.589-32.327 136.861-72.2 136.861-39.874 0-72.201-61.291-72.201-136.861 0-75.569 32.327-136.86 72.201-136.86 39.873 0 72.2 61.291 72.2 136.86zm64.792 0c0 67.724-11.37 122.621-25.394 122.621-14.023 0-25.394-54.916-25.394-122.621 0-67.704 11.371-122.621 25.399-122.621S512 77.668 512 145.387z" fill="#72757e"/>
                  </svg>
        </IconButton>
      </a>

    </div>
  );
};

export default SocialIcon;
