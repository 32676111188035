import React from "react";
import {Grid} from "@material-ui/core";
import {FaqContainer} from "./index";

const FaqVeteran = () => {
  return (
      <Grid container>
        <FaqContainer title='What is "Ghost Veterans"?'>
            Ghost Veterans is an elite group of users holding at least 20,000 Ghost.
	    These users are entitled to a Veterans reward in addition to the block reward when staking their Ghost.<br></br><br></br>
	    
	    This reward is currently 5.7 Ghost per block. This, along with the standard stake reward of 3.876 Ghost, gives veteran eligible addresses a total of 9.576 Ghost per block staked!
            
        </FaqContainer>
        <FaqContainer title="Where do these funds come from?">
            There are 11.4 Ghost generated with every new block. Of that, 34% or 3.876 Ghost is for the staker, 16% or 1.824 Ghost goes to the development fund,
            and 50% or 5.7 Ghost is for AGVR.<br></br><br></br>
            These funds are distributed completely decentralized and autonomously.
        </FaqContainer>
        <FaqContainer title="How can I become eligible for AGVR?">
            To become eligible for AGVR, first you must have a balance of at least 20,000 Ghost in an address. After you have 20,000 Ghost, you can check your eligibility status
            at <a style={{ color: "#AEFF00" }} href="https://explorer.myghost.org/vetlist" target="_blank" rel="noreferrer">explorer.MyGhost.org/Vetlist</a><br></br><br></br>
            Next, wait the maturity period. This is 21,600 blocks, or about 30 Days.<br></br><br></br>
            After the maturity period, your address should move from the pending to the current veterans list.
	    Once on the current veterans list you will begin to receive AGVR rewards with each block that you stake.<br></br><br></br>
	    In order to maintain eligibility, the balance of the AGVR eligible address must not fall below 20,000 Ghost for any reason.            
        </FaqContainer>
        <FaqContainer title="What if I stake at a pool?">
            You can stake any way that you like with AGVR. This includes pools! Since AGVR funds are paid directly to the staker, no AGVR funds are ever sent to a pool.
            Furthermore, you may also stake using a GhostVault or through hotstaking.
        </FaqContainer>
      </Grid>
  );
};

export default FaqVeteran;
