const fonts = {
  xl: 60,
  l: 48,
  ml: 22,
  m: 18,
  sm: 14,
  size13: 13,
  size16: 16,
  size25: 25,
  // only for mobile
  size40: 40,
  size28: 28,
  size12: 12
};

export default fonts;
