// Global ghost current client version
const GHOST_CURRENT_VERSION = "2.1.0";

// Define wallet by OS
export const DESKTOP_WINDOWS = {
  title: `Ghost Wallet for Windows`,
  link: `https://github.com/ghost-coin/ghost-desktop/releases/download/v${GHOST_CURRENT_VERSION}/ghost-desktop-${GHOST_CURRENT_VERSION}-win.exe`,
};
export const DESKTOP_LINUX = {
  title: `Ghost Wallet for Linux`,
  link: `https://github.com/ghost-coin/ghost-desktop/releases/download/v${GHOST_CURRENT_VERSION}/ghost-desktop-${GHOST_CURRENT_VERSION}-linux-x64.zip`,
};
export const DESKTOP_MAC = {
  title: `Ghost Wallet for MacOs`,
  link: `https://github.com/ghost-coin/ghost-desktop/releases/download/v${GHOST_CURRENT_VERSION}/ghost-desktop-${GHOST_CURRENT_VERSION}-mac.zip`,
};
export const MOBILE_ANDROID = {
  title: `Ghost Wallet for Android`,
  link: `https://play.google.com/store/apps/details?id=org.ghost.ghostelectrum`,
};

export const WEBAPP = {
  title: `Ghost SHELTR Wallet`,
  link: `https://app.sheltrwallet.com`,
};

// 05.01.2022
export const GHOST_CURRENT_STATE = {
  priceUri:
    "https://api.coingecko.com/api/v3/simple/price?ids=ghost-by-mcafee&vs_currencies=usd",
  avgStakedUri: "https://explorer.myghost.org/ext/getnethealth",
  maxSimulationNumber: 300000,
  defaultPrice: 0.3,
  defaultAvgStaked: 8500000,
  totalSupply: 20703319, // circulating supply
  secondsPerYear: 60 * 60 * 24 * 365, // one block every two minutes
  veteranReward: 2.673, // ~5.400 Ghost Veteran reward per block *changed to refelct the decrease in emission rate
  stakerReward: 3.726, // ~3.672 staking reward per block *changed to refelct the decrease in emmission rate
  masternodeReqCount: 20000, // 20k $GHOST required for veteran status
  masternodeCount: 480,
  currentYear: 5,
  roadmapSections: ["2020", "2021", "2022", "2023", "2024", "future"],
};
