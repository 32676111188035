import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import FontSize from "../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: 35,
        },
    },
    ghostDefiImg: {
        borderRadius: 15,
        width: "100%",
        maxWidth: "100%",
        height: '100%',
        maxHeight: '215px',
    },
    defiTitle: {
        fontSize: FontSize.size25,
        marginTop: 21,
        fontWeight: "bold",
        lineHeight: 1.2,
        width: 330,
        textDecoration: "none",
        color: "#FFF",
        [theme.breakpoints.down("xs")]: {
            fontSize: 15,
            width: '100%',
            paddingTop: 10,
            marginTop: 0,
            textAlign: 'center'
        },
    },
    defiDescription: {
        fontSize: FontSize.m,
        color: theme.palette.secondary.textColor,
        marginTop: 15,
        [theme.breakpoints.down("md")]: {
            fontSize: FontSize.size16,
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
            fontSize: 13,
        },
    },
    time: {
        fontSize: FontSize.sm,
        color: "#72757E",
        marginTop: 15,
        [theme.breakpoints.down("xs")]: {
            fontSize: 11,
            marginTop: 10,
        },
    },
    category: {
        padding: "15px 20px",
        borderRadius: 4,
        marginTop: 15,
        textAlign: 'center',
        maxWidth: 100,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            padding: "7px 13px",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
            maxWidth: '100%'
        },
    },
}));

const NewsCard = (props) => {
    const classes = useStyles();
    const { index, data } = props;
    return (
        <Grid item md={4} sm={12} xs={12} key={index} className={classes.mainContainer}>
            <a href = {data.link} rel="noreferrer" target = "_blank" style = {{textDecoration: "none"}}>
                <img src={data.image} alt={data.title} className={classes.ghostDefiImg}/>
            </a>
            <Typography className={classes.category} style={{backgroundColor: "rgba(174, 255, 0, 0.1)", color: "#AEFF00"}}>
                {data.type}
            </Typography>
            <Typography className={classes.defiTitle}>
                <a href={data.link} rel="noreferrer" target="_blank" className={classes.defiTitle}>{data.title}</a>
            </Typography>
            <Typography className={classes.defiDescription}>
                {data.descr}
            </Typography>
            <Typography className={classes.time}>{data.date}</Typography>
        </Grid>
    );
};

export default NewsCard;
