import React from "react";
import {Grid} from "@material-ui/core";
import {FaqContainer} from "./index";

const FaqExchange = () => {
  return (
      <Grid container>
        <FaqContainer title="Where can I buy Ghost?">
            The exchanges to purchase mainnet Ghost are listed at the top of the homepage.
        </FaqContainer>
        <FaqContainer title="What wallet can I use for Ghost?">
            Currently you can download the official Ghost Wallet here, <a style={{ color: "#AEFF00" }} href="https://github.com/ghost-coin/ghost-desktop/releases">Ghost Wallet</a>
        </FaqContainer>
        <FaqContainer title="Where is the mobile wallet?">
            We offer two great choices for your convenience.
            Ghost-Electrum is available on <a style={{ color: "#AEFF00" }} href="https://play.google.com/store/apps/details?id=org.ghost.ghostelectrum" target="_blank" rel="noreferrer">Google Play</a> for Android users, 
            while <a style={{ color: "#AEFF00" }} href="https://app.sheltrwallet.com" target="_blank" rel="noreferrer">SHELTR</a> is our flagship Progressive Web App (PWA) designed to work seamlessly with both iOS and Android.
        </FaqContainer>
        <FaqContainer title="Does the Ghost wallet update itself?">
            No. But the wallet updates the latest core automatically. You will need to update the main wallet client yourself by downloading the new version as it's released.
        </FaqContainer>
      </Grid>
  );
};

export default FaqExchange;
