import React, { Fragment } from "react";
import Footer from "../Footer";
import EmissionsWrapper from "./EmissionsWrapper";
import FooterDivider from "../../utils/FooterDivider";

const Emissions = () => {
  return (
    <Fragment>
      <EmissionsWrapper />
      <FooterDivider />
      <Footer />
    </Fragment>
  );
};

export default Emissions;
