import React from "react";

// MUI stuff
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import ghostRoadMapTabs from "../../assets/ghostRoadMap/ghostRoadMapTabs.svg";
import ghostRoadMapTabsAdaptive from "../../assets/ghostRoadMap/ghostRoadMapTabsAdaptive.svg";

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 0,
    paddingBottom: 22,
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "center 122px",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      backgroundImage: `none`,
    },
  },
  title: {
    fontSize: 20,
    width: 568,
    color: "#AEFF00",
    lineHeight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(93vw - 4ch)",
    },
  },
  month: {
    fontSize: 20,
    width: 568,
    color: "#FAFAFA",
    lineHeight: "25px",
    position: "relative",
    top: "25px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      left: "1ch",
    },
  },
  tabContainer: {
    margin: 0,
  },
  description: {
    letterSpacing: 0.5,
    fontSize: 14,
    color: theme.palette.secondary.textColor,
    width: 568,
    marginTop: 5,
    lineHeight: "18px",
    marginBottom: 15,
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 60,
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 60,
      width: "93vw",
    },
  },
}));

const arr2020 = {
  may: [
    {
      title: "WhitePaper Release",
      description:
        "The WhitePaper is officially released, and the blueprints for the future of privacy are layed out.",
    },
  ],
  june: [
    {
      title: "MainNet Launched",
      description:
        "Ghost's MainNet goes live, and Ghost is available to the public for the first time.",
    },
    {
      title: "Desktop Wallet v2.0.6 released",
      description:
        "The Ghost-desktop wallet is released. This will become a cornerstone of the Ghost ecosystem.",
    },
  ],
  july: [
    {
      title: "Ghost Partners with IvendPay",
      description:
        "IvendPay is a global Crypto payment system for retail, e-commerce and vending machines. " +
        "They make it Safe & Simple to pay for everyday products and services using your favorite crypto. ",
    },
    {
      title: "v0.19.1.7 released",
      description: "Various improvements to stability and syncing issues.",
    },
    {
      title: "Desktop Wallet v2.0.7 released",
      description: "Faster boot up speeds and cleaner GUI.",
    },
    {
      title: "Ghostman released",
      description:
        "ghostman is a CLI utility for managing your self-hosted staking nodes.",
    },
  ],
  august: [
    {
      title: "v0.19.1.8 released",
      description: "Housekeeping improvements to the codebase.",
    },
    {
      title: "New website",
      description: "Our website received a new fresh look.",
    },
  ],
};

const arr2021 = {
  january: [
    {
      title: "v0.19.1.9 released",
      description: "Stability improvements, which includes network upgrades.",
    },
  ],
  february: [
    {
      title: "Dandelion++ Research Published",
      description:
        "The Dandelion protocol has long set the stage for its place in the world of privacy. " +
        "Since inception, we have been researching this increadibly powerful protocol and thouroughly trying to understand its place within our blockchain's environment.",
    },
  ],
  march: [
    {
      title: "v0.19.1.10 released (legacy sunset)",
      description:
        "This version of Ghost-Core introduces a temporary removal of RingCT.",
    },
    {
      title: "Desktop Wallet v2.0.10 released",
      description: "Various Bug fixes.",
    },
  ],
  august: [
    {
      title: "v0.21.1.2 released",
      description: "Improvements to stability and network behavior.",
    },
    {
      title: "Desktop Wallet v2.0.11 released",
      description: "Various Bug fixes and UI/UX improvements.",
    },
  ],
  september: [
    {
      title: "v0.21.1.7 released",
      description:
        "Core v0.21.1.7 introduces the implementation of the legendary dandelion++ protocol into the ghost blockchain.",
    },
  ],
  december: [
    {
      title: "Fiat On-Ramp",
      description:
        "Our Fiat On-Ramp releases with the Russian Ruble as the first fiat exchange.",
    },
  ],
};

const arr2022 = {
  march: [
    {
      title: "Desktop Wallet v2.0.12 released",
      description:
        "Fine tuning wallet functionality, ensuring a smoother user experience with enhanced operational stability.",
    },
    {
      title: "v0.21.1.8 released (Dandelion++ removed) RingCT 2.0 released",
      description:
        "RingCT gets re-implemented and receives an upgrade to the overall protocol itself. Faster decoying, and network sync times. Dandelion++ removed temporarily",
    },
    {
      title:
        "Improvements to MLSAG (Multilayered Linkable Spontaneous Anonymous Group) signatures",
      description:
        "MLSAG is an acronym for Multilayered Linkable Spontaneous Anonymous Group.",
    },
    {
      title: "v0.21.1.9 released",
      description: "0.21.1.9 released, various bug fixes.",
    },
  ],
  april: [
    {
      title: "GhostVault released (Ghostman sunset)",
      description:
        "GhostVault is a tool for setting up and managing a coldstaking node with various optional levels of privacy.",
    },
  ],
  june: [
    {
      title: "Anonymous Zapping into Cold Staking Script implemented",
      description:
        "Directly Zap to the coldstaking script without changing your address.",
    },
    {
      title: "GhostPayBot released",
      description:
        "A quick and lightweight mobile wallet on the telegram platform.",
    },
  ],
  july: [
    {
      title: "Desktop Wallet v2.1.0 released",
      description:
        "Introduces the innovative 'zap anon' feature, refined address reuse options, and crucial fixes for validation errors and boot-up hangs.",
    },
  ],
  september: [
    {
      title: "v0.22.0.0 released",
      description:
        "v0.22.0.0 is a massive update. Prepares for full AGVR integration and removal of current tokenomic model.",
    },
    {
      title:
        "Improvements to CLSAG (Concise Linkable Spontaneous Anonymous Group) signatures",
      description:
        "Expanded anonymity sets and increased efficency in handling multiples anon input/outputs.",
    },
    {
      title: "Automated Ghost Veterean Rewards implemented",
      description:
        "Automated Ghost Veterans protocol implemented. This further decentralizes the blockchain and increases network stability throughout.",
    },
  ],
  october: [
    {
      title: "v0.22.1.0 released",
      description: "Hot fix release following AGVR integration.",
    },
    {
      title: "Ghost Electrum released",
      description:
        "GhostElectrum is a lightweight secure wallet. Built for compatibility and excellent user control.",
    },
  ],
  november: [
    {
      title: "Website Hosting Upgraded to IPFS protocol",
      description:
        "We've upgraded our website to become immutable, via the inter planatery filing system.",
    },
  ],
  december: [
    {
      title: "GhostpayBot Enhancements",
      description: "Faster response times and more user control settings.",
    },
  ],
};

const arr2023 = {
  april: [
    {
      title: "SHELTR released",
      description:
        "SHELTR is the next era for Ghost. This is a PWA and lightweight wallet with web3 compatibility integrated. This is by far our most user friendly wallet.",
    },
  ],
  may: [
    {
      title: "v0.22.2.0 released",
      description:
        "Various improvements to the core relating to AGVR specific issues that have been addressed.",
    },
    {
      title: "On-Chain Governance foundation laid",
      description:
        "Our research continues as we further decentralize our blockchain. On-Chain governance model has been established.",
    },
  ],
  june: [
    {
      title: "GhostSecret released",
      description:
        "A paste bin with AES-256 encryption for self-destructing messages.",
    },
    {
      title: "RingCT 3.0 & Bulletproofs+ research initiated",
      description:
        "Research has began for further understanding on the effects of doubling the max ring size in terms of scalability and security. " +
        "Decreasing fees and imporving range proofing times goes hand in hand with an upgrade to RingCT.",
    },
  ],
  august: [
    {
      title: "Wrapped Ghost on Polygon released",
      description:
        "Our Wrapped version of $GHOST has been released on the Polygon blockchain. This opens the door to endless amounts of liquidity and future partnerships with other projects",
    },
  ],
  september: [
    {
      title: "SHELTR v0.5.0b released",
      description: "Impovements to sync times, and various UI/UX improvements.",
    },
    {
      title: "Polygon Bridge released",
      description:
        "Our two-way decentralized bridge with the polygon networks makes interacting with other blockchains seamless.",
    },
  ],
};

const arr2024 = {
  january: [
    {
      title: "Website Makeover",
      description: "We've made improvements to our website design.",
    },
    {
      title: "Launch GSTVPN",
      description:
        "In collaboration with Specter Systems LLC, we launch our no KYC VPN service.",
    },
  ],
  february: [
    {
      title: "v0.23.0.0 released",
      description:
        "Gigantic update to the core features several developments including several updates from upstream, correction of rewards distribution, faster sync times and improved syncing along with massive security upgrades, and several other housekeeping updates.",
    },
  ],
};

const arrFut = [
  {
    title: "Dandelion++ re-implemnted (coming)",
    description:
      "Dandelion++ is an essential tool for ensuring Ghost users privacy.",
  },
  {
    title: "Bulletproofs+ implemented (coming)",
    description: "Offering up to 85% size reduction for lower fees.",
  },
  {
    title: "RingCT 3.0 (coming)",
    description: "A more efficient and secure RingCT implementation.",
  },
  {
    title:
      "DLSAG: Non-Interactive Refund Transactions For Interoperable Payment Channels in Ghost (in reseach)",
    description: "DLSAG will bring refundable anon transactions to Ghost.",
  },
  {
    title: "GhostChat",
    description: "A next level private messaging system.",
  },
  {
    title: "Hardware Wallet Support",
    description: "Hardware wallet support for mainchain Ghost.",
  },
  {
    title: "On-Chain Governance",
    description: "A system for voting on proposals on the Ghost blockchain.",
  },
];

const RoadMapTabsQ1 = () => {
  const classes = useStyles();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={classes.body}>
      {Object.entries(arr2020).map(([month, items], index) => (
        <div key={index} className={classes.tabContainer}>
          <div className={classes.leftSide}>
            <Typography
              className={`${classes.month} hide-narrow`}
              align="right"
            >
              {month.toProperCase()}
            </Typography>
            <Typography className={`${classes.month} hide-wide`} align="right">
              {month.substring(0, 3).toProperCase()}
            </Typography>
          </div>

          <div className={classes.rightSide}>
            {items.map((data, itemIndex) => (
              <div key={itemIndex} className={classes.tab}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RoadMapTabsQ2 = () => {
  const classes = useStyles();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={classes.body}>
      {Object.entries(arr2021).map(([month, items], index) => (
        <div key={index} className={classes.tabContainer}>
          <div className={classes.leftSide}>
            <Typography
              className={`${classes.month} hide-narrow`}
              align="right"
            >
              {month.toProperCase()}
            </Typography>
            <Typography className={`${classes.month} hide-wide`} align="right">
              {month.substring(0, 3).toProperCase()}
            </Typography>
          </div>

          <div className={classes.rightSide}>
            {items.map((data, itemIndex) => (
              <div key={itemIndex} className={classes.tab}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RoadMapTabsQ3 = () => {
  const classes = useStyles();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={classes.body}>
      {Object.entries(arr2022).map(([month, items], index) => (
        <div key={index} className={classes.tabContainer}>
          <div className={classes.leftSide}>
            <Typography
              className={`${classes.month} hide-narrow`}
              align="right"
            >
              {month.toProperCase()}
            </Typography>
            <Typography className={`${classes.month} hide-wide`} align="right">
              {month.substring(0, 3).toProperCase()}
            </Typography>
          </div>

          <div className={classes.rightSide}>
            {items.map((data, itemIndex) => (
              <div key={itemIndex} className={classes.tab}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
const RoadMapTabsQ4 = () => {
  const classes = useStyles();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={classes.body}>
      {Object.entries(arr2023).map(([month, items], index) => (
        <div key={index} className={classes.tabContainer}>
          <div className={classes.leftSide}>
            <Typography
              className={`${classes.month} hide-narrow`}
              align="right"
            >
              {month.toProperCase()}
            </Typography>
            <Typography className={`${classes.month} hide-wide`} align="right">
              {month.substring(0, 3).toProperCase()}
            </Typography>
          </div>

          <div className={classes.rightSide}>
            {items.map((data, itemIndex) => (
              <div key={itemIndex} className={classes.tab}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RoadMapTabsQ5 = () => {
  const classes = useStyles();

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={classes.body}>
      {Object.entries(arr2024).map(([month, items], index) => (
        <div key={index} className={classes.tabContainer}>
          <div className={classes.leftSide}>
            <Typography
              className={`${classes.month} hide-narrow`}
              align="right"
            >
              {month.toProperCase()}
            </Typography>
            <Typography className={`${classes.month} hide-wide`} align="right">
              {month.substring(0, 3).toProperCase()}
            </Typography>
          </div>

          <div className={classes.rightSide}>
            {items.map((data, itemIndex) => (
              <div key={itemIndex} className={classes.tab}>
                <Typography className={classes.title}>{data.title}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RoadMapTabsFut = () => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      {arrFut.map((data, index) => (
        <Grid key={index} className={classes.tab}>
          <Typography className={classes.title}>{data.title}</Typography>
          <Typography className={classes.description}>
            {data.description}
          </Typography>
        </Grid>
      ))}
    </div>
  );
};

export default RoadMapTabsQ1;
export {
  RoadMapTabsQ2,
  RoadMapTabsQ3,
  RoadMapTabsQ4,
  RoadMapTabsQ5,
  RoadMapTabsFut,
};
