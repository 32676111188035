import React, { Fragment } from "react";

// MUI stuff
import PrivacyFocused from "./PrivacyFocused";
import SpeedSecurity from "./SpeedSecurity";
import StackGhost from "./StackGhost";
import GhostCreatedDistributed from "./GhostCreatedDistributed";
import GhostReward from "./GhostReward";
import DiveDeeper from "./DiveDeeper";
import JoinCommunity from "./JoinCommunity";
import Footer from "../Footer";

const Home = () => {
  return (
    <Fragment>
      <PrivacyFocused />
      <SpeedSecurity />
      <StackGhost />
      <GhostCreatedDistributed />
      <GhostReward />
      <DiveDeeper />
      <JoinCommunity />
      <Footer />
    </Fragment>
  );
};

export default Home;
