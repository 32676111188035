import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import FontSize from "../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 75,
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },
  },
  stackContainer: {
    paddingLeft: 70,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingTop: 10,
    },
  },
  title: {
    fontSize: 33,
    paddingTop: 15,
    width: 450,
    fontWeight: "bold",
    lineHeight: 1.2,
    color: "#FFF",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
      width: 335,
      paddingTop: 10,
    },
  },
  category: {
    padding: "15px 20px",
    borderRadius: 4,
    textAlign: 'center',
    maxWidth: 100,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      padding: "7px 13px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  description: {
    fontSize: FontSize.m,
    width: 400,
    color: theme.palette.secondary.textColor,
    paddingTop: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: FontSize.size16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      paddingTop: 10,
      width: 285,
    },
  },
  time: {
    fontSize: FontSize.sm,
    color: "#72757E",
    paddingTop: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      paddingTop: 10,
    },
  },
  newsImage: {
    width: 576,
    height: 324,
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      height: '100%',
    },
  }
}));

const MainNews = (props) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <div>
      <div className="container">
        <Grid container className={classes.container}>
          <Grid item md={6} sm={12} xs={12}>
            <img alt="News representation" src={data.image} className={classes.newsImage}/>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className={classes.stackContainer}>
            <Typography className={classes.category} style={{backgroundColor: "rgba(174, 255, 0, 0.1)", color: "#AEFF00"}}>
              {data.type}
            </Typography>
            <div>
              <Typography className={classes.title}>
                <a href={data.link} rel="noreferrer" target="_blank" className={classes.title}>{data.title}</a>
              </Typography>
            </div>
            <div>
              <Typography className={classes.description}>
                {data.descr}
              </Typography>
            </div>
            <Typography className={classes.time}>{data.date}</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MainNews;
