import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import ghostrewardStakinglogo from "../../../../assets/ghostReward/ghostrewardStakinglogo-green.svg";
import ghostrewardVeteranlogo from "../../../../assets/ghostReward/ghostrewardVeteranlogo-green.svg";
import FontSize from "../../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 50,
    },
  },
  stakingLogo: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  title: {
    fontSize: FontSize.ml,
    width: 155,
    [theme.breakpoints.down("xs")]: {
      fontSize: FontSize.m,
      textAlign: "center",
      width: 130,
    },
  },
  description: {
    fontSize: FontSize.sm,
    color: theme.palette.secondary.textColor,
    width: 220,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      textAlign: "center",
      width: 185,
      marginTop: 10,
    },
  },
  btnarrow: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 50,
    },
  },
}));

const arr = [
  {
    logo: ghostrewardStakinglogo,
    title: "Staking",
    description:
      "Staking GHOST allows you to share in the fees generated by the Ghost blockchain. Staking has no minimum coin requirements.",
    btn: "Start staking",
  },
  {
    logo: ghostrewardVeteranlogo,
    title: "Ghost veteran",
    description:
      "Becoming a Ghost veteran requires at least 20,000 GHOST and is the best way to earn in the fees generated by the Ghost blockchain.",
    btn: "Start now",
  },
];
const RewardRightSide = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        {arr.map((data, index) => (
          <Grid item sm={6} xs={12} key={index}>
            <div className={classes.container}>
              <img
                src={data.logo}
                alt="Staking logo"
                className={classes.stakingLogo}
              />
              <Typography className={classes.title}>{data.title}</Typography>
              <Typography className={classes.description}>
                {data.description}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RewardRightSide;
