import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Font from "../../utils/FontSize";
import { GHOST_CURRENT_STATE } from "../../Ghost";

const useStyles = makeStyles((theme) => ({
  roadmapNav: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    transition: "background-color 0.3s ease",
  },
  sticky: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.background.paper,
  },
  active: {
    fontWeight: "bold",
    backgroundColor: "#aeff00 !important",
    color: "#232728 !important",
    border: "thin solid #aeff00 !important",
  },
  btnCommon: {
    width: "196px",
    borderRadius: 8,
    textAlign: "center",
    padding: "12px",
    [theme.breakpoints.down("md")]: {
      borderRadius: 6,
      fontSize: Font.size12,
    },
    [theme.breakpoints.down("xs")]: {
      width: "",
      borderRadius: 5,
      fontSize: Font.size10,
      padding: "5px",
    },
  },
}));

const RoadMapNav = () => {
  const defaultSelection = "2020";
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(defaultSelection);
  const [navbarHeight, setNavbarHeight] = useState(118);
  const activateRange = 250;
  const mobileWidth = 767;
  const sections = GHOST_CURRENT_STATE.roadmapSections;

  useEffect(() => {
    const handleResize = () => {
      const roadmapNav = document.getElementById("roadmap-nav");
      const rect = roadmapNav.getBoundingClientRect();
      setNavbarHeight(Math.max(0, roadmapNav.offsetTop - 15));
    };

    handleResize();

    return () => {
      //
    };
  }, []);

  console.log(navbarHeight);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      let closestSection = null;
      let closestDistance = Number.MAX_SAFE_INTEGER;

      for (const section of sections) {
        const element = document.getElementById(section);

        if (element && element.offsetParent !== null) {
          const rect = element.getBoundingClientRect();
          const distance = Math.abs(rect.top);

          if (distance < closestDistance && distance < activateRange) {
            closestDistance = distance;
            closestSection = section;
          }
        }
      }

      if (closestSection && closestSection !== activeLink) {
        setActiveLink(closestSection);
      }

      const navbar = document.getElementById("roadmap-nav");
      const spacer = document.getElementById("spacer");

      if (scrollPosition >= navbarHeight && !isScrolled) {
        setIsScrolled(true);
        navbar.classList.add("sticky-nav");
        spacer.classList.add("spacer-show");
      } else if (scrollPosition < navbarHeight && isScrolled) {
        setIsScrolled(false);
        navbar.classList.remove("sticky-nav");
        spacer.classList.remove("spacer-show");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    const pollerInterval = setInterval(() => {
      const scrollPosition = window.scrollY;

      if (scrollPosition <= activateRange && window.innerWidth < mobileWidth) {
        setActiveLink(defaultSelection);
      }
    }, 500);

    return () => {
      clearInterval(pollerInterval);
    };
  }, []);

  const handleNavClick = (id) => {
    const element = document.getElementById(id);
    const title_el = document.getElementById("roadmap-title-container");

    if (element) {
      if (window.innerWidth > mobileWidth) {
        sections.forEach((section) => {
          const wrapper = document.getElementById(section + "-wrapper");
          if (wrapper) {
            title_el.scrollIntoView({ behavior: "smooth" });

            if (section === id) {
              wrapper.style.opacity = "1";
              wrapper.style.pointerEvents = "auto";
              wrapper.style.transition = "opacity 0.3s ease-in-out";

              setTimeout(() => {
                wrapper.style.display = "block";
              }, 300);
            } else {
              wrapper.style.opacity = "0";
              wrapper.style.pointerEvents = "none";
              wrapper.style.transition = "opacity 0.3s ease-in-out";
              setTimeout(() => {
                wrapper.style.display = "none";
              }, 300);
            }
          }
        });

        // Remove active classes from all nav links
        const navLinks = document.querySelectorAll(".nav-link");
        navLinks.forEach((link) => link.classList.remove(classes.active));

        // Add active class to the clicked nav link
        const clickedNavLink = document.getElementById(id + "-nav");
        if (clickedNavLink) {
          clickedNavLink.classList.add(classes.active);
        }
      } else {
        if (id === defaultSelection) {
          title_el.scrollIntoView({ behavior: "smooth" });
        } else {
          const yOffset = -100;
          const y =
            element.getBoundingClientRect().top + window.scrollY + yOffset;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    }
  };

  const classes = useStyles();

  return (
    <nav
      id="roadmap-nav"
      className={`roadmap-navbar ${isScrolled ? "sticky-nav" : ""}`}
    >
      <a
        id="2020-nav"
        onClick={() => handleNavClick("2020")}
        className={` ${activeLink === "2020" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        2020
      </a>
      <a
        id="2021-nav"
        onClick={() => handleNavClick("2021")}
        className={` ${activeLink === "2021" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        2021
      </a>
      <a
        id="2022-nav"
        onClick={() => handleNavClick("2022")}
        className={` ${activeLink === "2022" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        2022
      </a>
      <a
        id="2023-nav"
        onClick={() => handleNavClick("2023")}
        className={` ${activeLink === "2023" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        2023
      </a>
      <a
        id="2024-nav"
        onClick={() => handleNavClick("2024")}
        className={` ${activeLink === "2024" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        2024
      </a>
      <a
        id="future-nav"
        onClick={() => handleNavClick("future")}
        className={` ${activeLink === "future" ? classes.active : ""} ${
          classes.btnCommon
        } nav-link`}
      >
        Future
      </a>
    </nav>
  );
};

export default RoadMapNav;
