import React, { Fragment } from "react";

import LatestNews from "./LatestNews";
import NewsContainer from "./NewsContainer";

import Footer from "../Footer";
import FooterDivider from "../../utils/FooterDivider";

const News = () => {
  return (
    <Fragment>
      <LatestNews />
      <NewsContainer />
      <FooterDivider />
      <Footer />
    </Fragment>
  );
};

export default News;
