import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import {Button, Grid} from "@material-ui/core";

import ghostrewardbg from "../../../assets/ghostReward/ghostrewardbg.svg";
import ghostrewardbgVector from "../../../assets/ghostReward/ghostrewardbgVector.svg";

import RewardLeftSide from "./RewardSection/RewardLeftSide";
import RewardRightSide from "./RewardSection/RewardRightSide";
import FontSize from "../../../utils/FontSize";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${ghostrewardbg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      minHeight: 783,
    },
    [theme.breakpoints.up("xs")]: {
      textAlign: 'center',
      paddingTop: 35,
    }
  },
  container: {
    paddingTop: 300,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: 'center',
    },
  },
  ghostrewardbgVector: {
    position: "absolute",
    top: 150,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  startMore: {
    textAlign: "center",
  },

  btnStart: {
    height: 65,
    width: 200,
    borderRadius: 8,
    marginTop: 87,
    border: "1px solid #fff",
    fontSize: FontSize.size16,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 25,
      height: 55,
      width: 160,
    },
  },
}));

const GhostReward = () => {
  const classes = useStyles();
  return (
    <div style={{ paddingTop: 25, position: "relative" }}>
      <div className={classes.maincontainer}>
        <img
          src={ghostrewardbgVector}
          alt="Overview"
          className={classes.ghostrewardbgVector}
        />
        <div className={classes.container}>
          <div className="container">
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <RewardLeftSide />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <RewardRightSide />
              </Grid>
            </Grid>
            <div className={classes.startMore}>
              <a href="https://github.com/ghost-coin/ghost-desktop/releases" style={{textDecoration: "none"}}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnStart}>
                  Start now
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GhostReward;
